<template>
  <div
    class="bg-image"
    style="
      background-image: url('https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1925&q=80');
    "
  >
    <div class="kiosk-wrapper">
      <div class="row kiosk-header">
        <div class="col-md-9">
          <h1 class="display-4 font-w300 text-white mb-2 fade" :class="{ in: !hideSlide }">{{ currentSlide.title }}</h1>
          <h2 class="font-w300 text-white-75 fade" :class="{ in: !hideSlide }">{{ currentSlide.subTitle }}</h2>
        </div>
        <div class="col-md-3 text-right">
          <div class="d-flex align-items-center justify-content-end">
            <img class="img-fluid" style="max-height: 100px" :src="kiosk.entity.companyLogo" />
            <div class="text-left ml-5">
              <p class="text-white-75 mb-0 font-size-h5">Powered by</p>
              <Logo :white="true" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="fade kiosk-body" :class="{ in: !hideSlide }">
        <keep-alive>
          <component :is="currentSlide.component" :data="currentSlide.data" :slide="currentSlide" />
        </keep-alive>
      </div>
      <div v-else class="pt-5">
        <Spinner variant="white" size="xl" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Logo from '@/components/Logo';
import Spinner from '@/components/Spinner';
import KioskEnergyLeaderboard from './slides/KioskEnergyLeaderboard';
import KioskHHGraph from './slides/KioskHHGraph';
import KioskBenchmark from './slides/KioskBenchmark';
import KioskLocalNews from './slides/KioskLocalNews';
import KioskEnergy from './slides/KioskEnergy';
import KioskCarbon from './slides/KioskCarbon';

export default {
  name: 'KioskAssetView',
  components: {
    KioskEnergy,
    KioskCarbon,
    KioskEnergyLeaderboard,
    KioskHHGraph,
    KioskBenchmark,
    KioskLocalNews,
    Logo,
    Spinner
  },
  data() {
    return {
      hideSlide: false,
      interval: null
    };
  },
  computed: {
    ...mapGetters({
      asset: 'kiosk/asset',
      kiosk: 'kiosk/kiosk',
      slideIndex: 'kiosk/slideIndex',
      slides: 'kiosk/slides',
      loading: 'kiosk/assetLoading'
    }),
    currentSlide() {
      return this.filteredSlides[this.slideIndex];
    },
    filteredSlides() {
      return this.slides
        .filter(slide => {
          if (!this.kiosk.slides || this.kiosk.slides.length < 2) return false;

          // Check slides that are strings
          const isMatchingStringSlides = this.kiosk.slides.filter(s => typeof s === 'string').some(s => `Kiosk${s}` === `${slide.component}`);

          // Check slides that are objects
          const isMatchingObjSlides = this.kiosk.slides.filter(s => typeof s !== 'string' && s.id).some(s => `Kiosk${s.id}` === `${slide.component}`);

          // Exclude this slide if it does not match any Vue components
          if (!isMatchingStringSlides && !isMatchingObjSlides) return false;

          return true;
        })
        .map(slide => {
          // Populate slide with any data from the kiosk slides
          const matchingSlide = this.kiosk.slides.find(s =>
            typeof s === 'string' ? `Kiosk${s}` === `${slide.component}` : `Kiosk${s.id}` === `${slide.component}`
          );

          if (!matchingSlide || typeof matchingSlide === 'string' || !matchingSlide.data) return slide;

          return { ...slide, data: matchingSlide.data };
        });
    }
  },
  watch: {
    'kiosk.slideInterval'(a, b) {
      if (a !== b) this.resetTimer();
    }
  },
  async mounted() {
    this.resetTimer();

    window.addEventListener('keyup', e => {
      if (e.key === 'ArrowRight' || e.key === 'Enter') {
        this.transition();
        this.resetTimer();
      }
      if (e.key === 'ArrowLeft') {
        this.transition(true);
        this.resetTimer();
      }
    });

    if (this.$route.query.slide) {
      this.setSlideIndex(parseInt(this.$route.query.slide, 10));
    }

    this.setLoading(true);

    await this.refresh();
    this.setLoading(false);

    this.interval = setInterval(this.refresh, 15 * 60 * 1000);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', null);
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions({
      getAsset: 'kiosk/getAsset',
      getNews: 'kiosk/getNews',
      getAssetSmartDataConsumption: 'kiosk/getAssetSmartDataConsumption',
      getAssetReadingConsumption: 'kiosk/getAssetReadingConsumption',
      getAssetInvoiceConsumption: 'kiosk/getAssetInvoiceConsumption',
      getAssetEmissions: 'kiosk/getAssetEmissions',
      getAssetAccount: 'kiosk/getAssetAccount'
    }),
    ...mapMutations({
      setDebug: 'kiosk/SET_DEBUG',
      setSlideIndex: 'kiosk/SET_SLIDE_INDEX',
      setLoading: 'kiosk/SET_ASSET_LOADING'
    }),
    transition(back = false) {
      this.hideSlide = true;

      setTimeout(() => {
        this.hideSlide = false;

        if (this.$route.query.slide || this.$route.query.slide === 0) {
          this.setSlideIndex(this.$route.query.slide);
        } else if (!back) {
          if (this.slideIndex >= this.filteredSlides.length - 1) {
            this.setSlideIndex(0);
          } else {
            this.setSlideIndex(this.slideIndex + 1);
          }
        } else {
          if (this.slideIndex === 0) {
            this.setSlideIndex(this.filteredSlides.length - 1);
          } else {
            this.setSlideIndex(this.slideIndex - 1);
          }
        }
      }, 750);
    },
    async refresh() {
      const refreshTimer = new Date().getTime();

      // Asset-level Data
      await this.getAsset({ id: this.$auth.user.kioskId });

      // Handle Carbon slide's renewable % from account contract
      const carbonSlide = this.kiosk.slides.find(s => typeof s !== 'string' && s.id && s.id === 'Carbon');

      if (carbonSlide && carbonSlide.data && carbonSlide.data.supplierHighlight.accountId) {
        await this.getAssetAccount({
          id: this.$auth.user.kioskId,
          accountId: carbonSlide.data.supplierHighlight.accountId
        });
      }

      await this.getNews({ id: this.$auth.user.kioskId });

      await this.getAssetInvoiceConsumption({
        id: this.$auth.user.kioskId,
        params: {
          startDate: moment().subtract(1, 'month').subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
          endDate: moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
          dateBreakdown: 'monthly'
        }
      });

      await this.getAssetSmartDataConsumption({
        id: this.$auth.user.kioskId,
        params: {
          startDate: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
          endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          dateBreakdown: 'daily'
        }
      });

      await this.getAssetReadingConsumption({
        id: this.$auth.user.kioskId,
        params: {
          startDate: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
          endDate: moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
          subMeterBreakdown: 'true'
        }
      });

      await this.getAssetEmissions({
        id: this.$auth.user.kioskId,
        key: `curr`,
        params: {
          startDate: moment().subtract(1, 'month').subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
          endDate: moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD')
        }
      });

      await this.getAssetEmissions({
        id: this.$auth.user.kioskId,
        key: `prev`,
        params: {
          startDate: moment().subtract(1, 'month').subtract(2, 'year').startOf('month').format('YYYY-MM-DD'),
          endDate: moment().subtract(2, 'month').subtract(1, 'year').endOf('month').format('YYYY-MM-DD')
        }
      });

      this.setDebug({
        lastAssetRefresh: moment().format('DD/MM/YYYY HH:mm:ss'),
        lastAssetRefreshDuration: ((new Date().getTime() - refreshTimer) / 1000).toFixed(2)
      });
    },
    resetTimer() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.transition();
      }, this.kiosk.slideInterval || 30000);
    }
  }
};
</script>
<style lang="scss">
.kiosk-header {
  padding: 2rem;
  border-bottom: 0.3rem solid rgb(192 197 204 / 30%) !important;
}
.kiosk-body {
  padding: 2rem;
}

.kiosk-wrapper {
  // background-color: rgba(200, 16, 46, 0.9) !important;
  background-color: rgb(88 88 88 / 90%) !important;
  min-height: 100vh;
  overflow: hidden;
}
.bg-brand {
  background: linear-gradient(45deg, rgb(202 20 45 / 100%), rgb(202 20 45 / 80%), rgb(202 20 45 / 100%));
}
.kiosk-block {
  margin-bottom: 1.5rem;
  box-shadow: none;

  &.block-rounded {
    border-radius: 0.25rem;
  }

  &.block-rounded > .block-content:last-child {
    border-bottom-right-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
}
.fade {
  transition: all 0.75s ease;
  opacity: 0;

  &.in {
    opacity: 1;
  }
}
.kiosk-divider-right {
  border-right: 0.15rem solid rgb(192 197 204 / 30%) !important;
}
</style>
