var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-image",
    staticStyle: {
      "background-image": "url('https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1925&q=80')"
    }
  }, [_c('div', {
    staticClass: "kiosk-wrapper"
  }, [_c('div', {
    staticClass: "row kiosk-header"
  }, [_c('div', {
    staticClass: "col-md-9"
  }, [_c('h1', {
    staticClass: "display-4 font-w300 text-white mb-2 fade",
    class: {
      in: !_vm.hideSlide
    }
  }, [_vm._v(_vm._s(_vm.currentSlide.title))]), _c('h2', {
    staticClass: "font-w300 text-white-75 fade",
    class: {
      in: !_vm.hideSlide
    }
  }, [_vm._v(_vm._s(_vm.currentSlide.subTitle))])]), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "max-height": "100px"
    },
    attrs: {
      "src": _vm.kiosk.entity.companyLogo
    }
  }), _c('div', {
    staticClass: "text-left ml-5"
  }, [_c('p', {
    staticClass: "text-white-75 mb-0 font-size-h5"
  }, [_vm._v("Powered by")]), _c('Logo', {
    attrs: {
      "white": true
    }
  })], 1)])])]), !_vm.loading ? _c('div', {
    staticClass: "fade kiosk-body",
    class: {
      in: !_vm.hideSlide
    }
  }, [_c('keep-alive', [_c(_vm.currentSlide.component, {
    tag: "component",
    attrs: {
      "data": _vm.currentSlide.data,
      "slide": _vm.currentSlide
    }
  })], 1)], 1) : _c('div', {
    staticClass: "pt-5"
  }, [_c('Spinner', {
    attrs: {
      "variant": "white",
      "size": "xl"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }