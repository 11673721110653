<template>
  <div class="block block-transparent kiosk-block">
    <div class="block-content block-content-full">
      <ColChart :options="chartData" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import ColChart from '@/components/kiosk/ColChart';

export default {
  name: 'KioskHHGraph',
  components: {
    ColChart
  },
  data() {
    return {};
  },
  computed: {
    chartData() {
      return {
        chart: {
          type: 'column',
          height: '750px'
        },
        title: {
          text: ``
        },
        yAxis: {
          title: {
            text: 'Usage (kWh)'
          },
          min: 0
        },
        tooltip: { enabled: false },
        series: [
          {
            type: 'column',
            data: this.sampleData
          }
          // {
          //   type: 'spline',
          //   name: 'Average',
          //   data: this.sampleData2
          // }
        ],
        xAxis: {
          categories: new Array(48).fill(0).map((j, i) =>
            moment()
              .startOf('day')
              .add(i / 2, 'hours')
              .format('HH:mm')
          ),
          labels: {
            padding: 20,
            step: 2 // this will show every second label
          }
        }
      };
    },
    sampleData() {
      return [
        ...new Array(12).fill(0).map(() => Math.random() * 100 + 100),
        ...new Array(24).fill(0).map(() => Math.random() * 100 + 300),
        ...new Array(12).fill(0).map(() => Math.random() * 100 + 100)
      ];
    },
    sampleData2() {
      return [
        ...new Array(12).fill(0).map(() => Math.random() * 60 + 80),
        ...new Array(24).fill(0).map(() => Math.random() * 30 + 170),
        ...new Array(12).fill(0).map(() => Math.random() * 60 + 60)
      ];
    }
  }
};
</script>
<style lang="scss" scoped></style>
