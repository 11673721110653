var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-transparent kiosk-block"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('ColChart', {
    attrs: {
      "options": _vm.chartData
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }