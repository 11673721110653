<template>
  <div class="kiosk-chart">
    <div v-if="loading || options.series.length === 0" class="d-flex justify-content-center">
      <div class="text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading graph...</span>
        </div>
        <div class="font-w600">Loading graph...</div>
      </div>
    </div>
    <Chart v-else :key="options.series.length" :options="combinedChartOptions" />
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'Graph',
  components: {
    Chart
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({
        series: []
      })
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      defaultChartOptions: {
        chart: {
          type: 'column'
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Label'
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y:,.0f}'
        },
        colors: ['#4d9575', '#dddddd', '#ffd15e'],
        series: [],
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        }
      }
    };
  },
  computed: {
    combinedChartOptions() {
      return { ...this.defaultChartOptions, ...this.options };
    }
  }
};
</script>
