var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loadingAction.getAssetSmartDataConsumption ? _c('Spinner') : _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "kiosk-card kiosk-graph mb-4"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("Energy Consumption (kWh)")]), _c('LineChart', {
    attrs: {
      "options": _vm.consumptionChartData
    }
  })], 1), _c('div', {
    staticClass: "d-inline-block bg-black-25 p-3"
  }, [_c('ul', {
    staticClass: "list-unstyled text-white font-size-h2 mb-0"
  }, [_c('li', {
    staticClass: "mb-2"
  }, [_c('i', {
    staticClass: "fa fa-circle",
    staticStyle: {
      "color": "#4d9575"
    }
  }), _vm._v(" " + _vm._s(_vm.periods.smartData.current) + " ")]), _c('li', [_c('i', {
    staticClass: "fa fa-circle",
    staticStyle: {
      "color": "#dddddd"
    }
  }), _vm._v(" " + _vm._s(_vm.periods.smartData.previous) + " ")])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "kiosk-card kiosk-graph mb-4"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("Energy Consumption By Floor (kWh)")]), _c('BarChart', {
    attrs: {
      "options": _vm.energyPerformanceChartData
    }
  })], 1), _c('div', {
    staticClass: "d-inline-block bg-black-25 p-3"
  }, [_c('ul', {
    staticClass: "list-unstyled text-white font-size-h2 mb-0"
  }, [_c('li', {
    staticClass: "mb-2"
  }, [_c('i', {
    staticClass: "fa fa-circle",
    staticStyle: {
      "color": "#4d9575"
    }
  }), _vm._v(" " + _vm._s(_vm.periods.reading.current) + " ")]), _c('li', [_c('i', {
    staticClass: "fa fa-circle",
    staticStyle: {
      "color": "#dddddd"
    }
  }), _vm._v(" " + _vm._s(_vm.periods.reading.previous) + " ")])])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }