<template>
  <div>
    <Spinner v-if="loadingAction.getAssetSmartDataConsumption" />

    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="kiosk-card kiosk-graph mb-4">
            <!-- Energy Consumption Past 7 Days -->
            <h2 class="kiosk-card--title">Energy Consumption (kWh)</h2>
            <LineChart :options="consumptionChartData" />
          </div>
          <div class="d-inline-block bg-black-25 p-3">
            <ul class="list-unstyled text-white font-size-h2 mb-0">
              <li class="mb-2">
                <i class="fa fa-circle" style="color: #4d9575"></i>
                {{ periods.smartData.current }}
              </li>
              <li>
                <i class="fa fa-circle" style="color: #dddddd"></i>
                {{ periods.smartData.previous }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="kiosk-card kiosk-graph mb-4">
            <h2 class="kiosk-card--title">Energy Consumption By Floor (kWh)</h2>
            <!-- Energy Performance Breakdown by Tenant Meter -->
            <BarChart :options="energyPerformanceChartData" />
          </div>
          <div class="d-inline-block bg-black-25 p-3">
            <ul class="list-unstyled text-white font-size-h2 mb-0">
              <li class="mb-2">
                <i class="fa fa-circle" style="color: #4d9575"></i>
                {{ periods.reading.current }}
              </li>
              <li>
                <i class="fa fa-circle" style="color: #dddddd"></i>
                {{ periods.reading.previous }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import BarChart from '@/components/kiosk/BarChart';
import LineChart from '@/components/kiosk/LineChart';
import Spinner from '@/components/Spinner';

export default {
  name: 'KioskEnergy',
  components: {
    BarChart,
    LineChart,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      kiosk: 'kiosk/kiosk',
      asset: 'kiosk/asset',
      assetConsumption: 'kiosk/assetConsumption',
      loadingAction: 'kiosk/loadingAction'
    }),
    periods() {
      return {
        smartData: {
          current: 'Last 7 Days',
          previous: 'Previous Period'
        },
        reading: {
          current: moment().subtract(2, 'month').endOf('month').format('MMMM YYYY'),
          previous: moment().subtract(3, 'month').startOf('month').format('MMMM YYYY')
        }
      };
    },
    filteredReadingData() {
      if (!this.data || !this.data.mainConsumption) {
        const meters = this.assetConsumption.reading.meterConsumption;

        return meters.map(m => ({
          consumption: m.monthlyConsumption.slice(1, 2).reduce((sum, c) => sum + c.consumption, 0),
          prevConsumption: m.monthlyConsumption.slice(0, 1).reduce((sum, c) => sum + c.consumption, 0),
          label: m.meterName
        }));
      }

      const meters = this.assetConsumption.reading.meterConsumption.filter(m => this.data.mainConsumption.accountIds.includes(m.accountId));

      if (!this.data.subMeterConsumption) {
        return meters.map(m => ({
          consumption: m.monthlyConsumption.slice(1, 2).reduce((sum, c) => sum + c.consumption, 0),
          prevConsumption: m.monthlyConsumption.slice(0, 1).reduce((sum, c) => sum + c.consumption, 0),
          label: m.meterName
        }));
      }

      return Object.keys(this.data.subMeterConsumption.groups).map(groupName => {
        const meterNumbers = this.data.subMeterConsumption.groups[groupName];

        const consumptionOfGroup = meters
          .filter(meter => meterNumbers.includes(meter.meterSerialNumber))
          .reduce((sum, meter) => {
            return sum + meter.monthlyConsumption.slice(1, 2).reduce((s, c) => s + c.consumption, 0);
          }, 0);

        const prevConsumptionOfGroup = meters
          .filter(meter => meterNumbers.includes(meter.meterSerialNumber))
          .reduce((sum, meter) => {
            return sum + meter.monthlyConsumption.slice(0, 1).reduce((s, c) => s + c.consumption, 0);
          }, 0);

        return {
          consumption: consumptionOfGroup,
          prevConsumption: prevConsumptionOfGroup,
          label: groupName
        };
      });
    },
    consumptionChartData() {
      return {
        title: {
          text: '',
          useHTML: true
        },
        xAxis: {
          categories: this.assetConsumption.smartData.dailyConsumption.map(c => moment(c.period).format('ddd')).slice(0, 7),
          title: {
            text: ''
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        series: [
          {
            name: 'Current Period',
            data: this.assetConsumption.smartData.dailyConsumption.map(c => c.consumption).slice(7, 14),
            marker: {
              symbol: 'circle'
            }
          },
          {
            name: 'Previous Period',
            data: this.assetConsumption.smartData.dailyConsumption.map(c => c.consumption).slice(0, 7),
            marker: {
              symbol: 'circle'
            }
          }
        ],
        tooltip: { enabled: false }
      };
    },
    energyPerformanceChartData() {
      return {
        title: {
          text: '',
          useHTML: true
        },
        xAxis: {
          categories: this.filteredReadingData.map(d => d.label),
          title: {
            text: ''
          }
        },
        yAxis: {
          title: {
            text: '',
            margin: 20
          },
          min: 0
        },
        series: [
          {
            name: 'Current Period',
            data: this.filteredReadingData.map(d => d.consumption)
          },
          {
            name: 'Previous Period',
            data: this.filteredReadingData.map(d => d.prevConsumption)
          }
        ],
        tooltip: { enabled: false }
      };
    }
  }
};
</script>
<style lang="scss"></style>
